<template>
  <section class="maas-basic-table">

    <div v-if="hasSearchForm" class="ms-search">
      <el-form ref="elForm" v-bind="{ height: '100%', ...tableAttrs }" :model="form" inline @submit.native.prevent>
        <slot name="formItem"></slot>
        <el-form-item>
          <el-button :loading="loading" type="primary" @click="search">查询</el-button>
          <el-button v-if="hasResetBtn" class="reset-btn" @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <section v-if="hasHeader || hasNew" class="table-head">
      <el-form inline>
        <div class="ms-table-head-operate">
          <slot name="tableHeadOperation"></slot>
        </div>
        <el-button v-if="hasNew" class="reset-btn" @click="onDefaultNew">
          <i class="el-icon-circle-plus-outline"></i>
          {{ newText }}
        </el-button>
      </el-form>
    </section>

      <div class="page-wrapper">
          <el-pagination
              v-if="hasPagination"
              :current-page="pageNum"
              :page-sizes="pageSizes"
              :page-size="paginationSize"
              :layout="layout"
              :total="total"
              background
              class="el-pagination__maia"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
      </div>
      <section>
          <slot name="otherContent"></slot>
      </section>
    <section ref="tableWrap" class="ms-table">
      <el-table size="medium" ref="basicTable" v-loading="loading" :max-height="maxheight" v-bind="tableAttrs" :data="data" :row-class-name="rowClassName" stripe class="is-grey" v-on="$listeners">
        <el-table-column v-for="col in formatterColumns" :key="col.prop" v-bind="{ align: columnsAlign, headerAlign: col.align || 'left', ...col }"/>
      </el-table>
    </section>

  </section>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'
  // 项目中使用需引入
  import request from '@/utils/request'
  import {isPcClient} from "@/utils/utils";


  export default {
    name: 'MsTable',
    props: {
      // 手否有表头栏
      hasHeader: {
        type: Boolean,
        default: false
      },
      newText: {
        type: String,
        default: '新建'
      },
      // 是否有新增按钮
      hasNew: {
        type: Boolean,
        default: false
      },
      // 是否有搜索表单
      hasSearchForm: {
        type: Boolean,
        default: true
      },
      // 搜索参数
      searchForm: {
        type: Object,
        default: () => ({})
      },
      // 额外搜索参数
      extraQuery: {
        type: Object,
        default: () => ({})
      },
      /**
       * 表格列配置
       * @link https://element.eleme.cn/#/zh-CN/component/table#table-column-attributes
       */
      // columns: {
      //   type: Array,
      //   default: () => []
      // },
      // 请求url地址
      tableAction: {
        type: Object,
        default: () => {}
      },
      url: {
        type: String,
        default: ''
      },
      // 是否需要分页
      hasPagination: {
        type: Boolean,
        default: true
      },
      // 是否需要操作列
      hasOperation: {
        type: Boolean,
        default: false
      },
      /**
       * 操作列属性
       * @link https://element.eleme.cn/#/zh-CN/component/table#table-column-attributes
       */
      operationAttrs: {
        type: Object,
        default() {
          return { width: '', fixed: 'right' }
        }
      },
      tableAttrs: {
        type: Object,
        default() {
          return {}
        }
      },
      // 每页显示个数选择器的选项设置
      pageSizes: {
        type: Array,
        default: () => [10, 50, 100, 200]
      },
      // 每页显示条目个数
      pageSize: {
        type: Number,
        default: 10
      },
      // 组件布局
      layout: {
        type: String,
        default: 'total, sizes, prev, pager, next, jumper'
      },
      // 是否重置搜索按钮
      hasResetBtn: {
        type: Boolean,
        default: false
      },
      // 接口请求方式 post/get
      method: {
        type: String,
        default: 'post'
      },
      filterRequestData: {
        type: Function,
        default: data => data
      },
      maxheight: {
        type: [String, Number],
        default: '30000'
      },
      initSearch: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        form: {},
        initForm: null,
        data: [],
        columns:[],
        total: 0,
        pageNum: 1,
        paginationSize: this.pageSize || this.pageSizes[0],
        loading: false,
        tableMaxHeight: this.maxheight,
	      baseApi:'',
        imgPrefixMap:{},
        lock:false
      }
    },
    computed: {
      rowClassName() {
        return this.tableAttrs.rowClassName || this.tableAttrs['row-class-name'] || ''
      },
      columnsAlign() {
        if (this.columns.some(col => col.columns && col.columns.length)) {
          // 多级表头默认居中
          return 'center'
        } else {
          return ''
        }
      },
      /**
       * 处理表格没数据时，展示短横线
       */
      formatterColumns() {
        return this.columns.map(item => {
          let { formatter, prop } = item
          if (!formatter) {
            formatter = row => (row[prop] === 0 ? 0 : row[prop] || '-')
          }
          return {
            ...item,
            formatter
          }
        })
      }
    },
    watch: {
    },
    created() {
      this.initSearch && this.getList()
    },
    mounted() {
			this.baseApi = process.env.VUE_APP_BASE_API
    },
    methods: {
      handleResize() {
        this.$nextTick(() => {
          this.tableMaxHeight = this.tableWrapHeight()
        })
      },
      appendRow(row) {
        this.data.unshift(row)
        this.total += 1
        if (this.data.length > this.paginationSize) {
          this.data.pop()
        }
      },
      basicTable() {
        return this.$refs.basicTable
      },
      tableWrap() {
        return this.$refs.tableWrap
      },
      tableWrapHeight() {
        return this.tableWrap()?.clientHeight || 'auto'
      },
      onDefaultNew() {
        this.$emit('onDefaultNew')
      },
      handleSizeChange(val) {
        this.paginationSize = val
        this.pageNum = 1
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.getList()
      },
      resetForm() {
        this.$parent.resetForm()
      },
      search() {
        this.$nextTick(() => {
          this.pageNum = 1
          this.getList()
        })
      },
      async getList(otherQuery = {}) {
        const { url } = this

        if (!url) {
          console.warn('DataTable: url 为空, 不发送请求')
          return
        }

        let params = {
          pageNum: this.pageNum,
          pageSize: this.paginationSize,
          ...this.extraQuery,
          ...this.searchForm,
          ...otherQuery
        }
        params = this.filterRequestData(params)
        params = Object.keys(params)
          .filter(k => ![undefined, null].includes(params[k]))
          // eslint-disable-next-line no-sequences
          .reduce((obj, k) => ((obj[k] = params[k]), obj), {})

        try {
          this.loading = true
          const requestObject = {
            url,
            method: this.method
          }
          if (this.method === 'post') {
            requestObject.data = params
          } else {
            requestObject.params = params
          }

          const { data } = await request({ ...requestObject })

          if (data.list) {
            const { list, pageNum, pageSize, pages } = data
            const total = Number(data.total)
            this.data = [...list]
            this.total = total
            data.otherMap ? this.$parent.setOtherMap(data.otherMap) : ''
            this.columns = data.columns

            //如果有本地服务器数据,则预处理
            let imgPrefixList = []
            await this.data.forEach(item => {
              let selectLocalImgList = item.selectLocalImgList
              if (selectLocalImgList === undefined || (typeof selectLocalImgList) !== 'object') {
                return
              }
              if (selectLocalImgList[0] !== undefined && selectLocalImgList[0].indexOf('http') !== 0) {
                let prefix = selectLocalImgList[0].split(',')[0]
                if (imgPrefixList.indexOf(prefix) === -1) {
                  imgPrefixList.push(prefix)
                }
              }
            })
            console.log('共找到图片前缀', imgPrefixList)
            if (isPcClient()) {
              for (const item1 of imgPrefixList) {
                await pywebview.api.getFilePrefix(item1).then(res => {
                  this.imgPrefixMap[item1] = res
                })
              }
            }

            this.columns.forEach(item => {
            if (item.type === 'img') {
              item.formatter = ({imgUrl, imgUrlAll, autoGenImg, selectLocalImgList, title, content}) => {
                if ((autoGenImg === undefined || autoGenImg === true || selectLocalImgList === undefined) && title !== content) {
                  return (
                    <div v-loading={imgUrl === '$empty'} class="list-img">
                      <img v-show={imgUrl !== '$empty'} src={`${imgUrlAll}`} style="width: 100px;" alt=""/>
                    </div>
                  );
                } else {
                  let localImgUrl = ""
                  let localImgStr = selectLocalImgList[0]
                  if (localImgStr.indexOf('http') !== 0) {

                    let imgPrefix = localImgStr.split(',')[0]
                    let tail = localImgStr.split(',')[1]

                    if (this.imgPrefixMap[imgPrefix]) {
                      localImgUrl = this.imgPrefixMap[imgPrefix] + tail
                    } else {
                      console.log('图片加载出错')
                    }
                  }
                  return (
                    <div className="list-img">
                      <img src={`${localImgUrl}`} style="width: 100px;" alt=""/>
                    </div>
                  );
                }
              }
              //图片处理结束
            } else if (item.type === 'loading') {

              item.formatter = ({contentInList}) => {
                return (
                  <div v-loading={contentInList === '$empty'} class="list-img">
                    {contentInList}
                  </div>
                );
              }}
	          })
            this.columns.push(this.tableAction)
            this.pageNum = pageNum || this.pageNum
            const lastPage = Math.ceil(total / pageSize)
            if (lastPage > 0 && (lastPage < pages || pageNum > pages)) {
              this.pageNum = lastPage
              await this.getList()
            }
          } else {
            this.data = data
            this.total = data.length
          }
          this.$nextTick(() => {
            this.$refs.basicTable.doLayout()
          })

          this.$emit('loaded', this.data)
        } catch (error) {
          console.log(error)
          this.$emit('loadedErr')
          this.total = 0
          this.pageNum = 1
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .maas-basic-table {
    display: flex;
    height: 100%;
    // padding: 0 12px;
    flex-direction: column;
    background-color: #fff;
    border-radius: 6px;
    box-sizing: border-box;

    .ms-search {
      flex-shrink: 0;
      padding: 12px 12px 0;
      background: #fff;
    }

    .table-head {
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .ms-table {
      flex: 1;
      overflow: hidden;
      background: #fff;
      padding-bottom: 20px;

      ::v-deep .el-button {
          margin-bottom: 3px;
      }
	    .row-content {
		    min-height: 3rem;
		    display: flex;
		    align-items: center;
		    justify-content: center;
	    }
    }

    .page-wrapper {
      text-align: right;
      padding: 0 10px;
	    margin-bottom: 1rem;
    }
    ::v-deep .el-pagination{
        white-space: pre-wrap;
    }
  }
  .list-img {
	  height:4rem;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  align-content: center;
  }
</style>
